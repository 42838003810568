export const STATIC = 'STATIC';
export const PH_CLICK = 'PH_CLICK';
export const US = 'US';
export const UK = 'UK';
export const PRICING = 'PRICING';
export const FREE_TIER = 'FREE_TIER';
export const PRO = 'PRO';
export const BUSINESS = 'BUSINESS';
export const TRY_FOR_FREE = 'TRY_FOR_FREE';
export const START_FIRST_MEETING = 'START_FIRST_MEETING';
export const SHARE_SCREEN = 'SHARE_SCREEN';

// export function googleAnalyticsEventTracker({ eventAction, eventLabel }) {
//   return;
//   const trackingInfo = {
//     event_category: STATIC,
//     event_label: eventLabel,
//   };
//   if (gtag) {
//     gtag('event', eventAction, trackingInfo);
//   }
// }

export function recordMouseClickEvents(event) {
  const { target } = event;
  const eventLabel = target.getAttribute('data-ga-event');
  const eventAction = target.getAttribute('data-cont');
  if (eventLabel && eventAction) {
    // googleAnalyticsEventTracker({
    //   eventLabel,
    //   eventAction,
    // });
    window.location.href = eventAction;
  }
}
