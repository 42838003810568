// Triggering the function when the DOM is loaded completely

const documentReady = (callback) => {
  if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
  ) {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};

export default documentReady;
