export const toggleHeaderView = (headerOpen) => {
  const hamburger = document.querySelector('#hamburger');
  const navlinks = document.querySelector('#navlinks');
  if (headerOpen) {
    hamburger.classList.add('animate');
    navlinks.classList.add('open');
  } else {
    hamburger.classList.remove('animate');
    navlinks.classList.remove('open');
  }
};

export const addHeaderClickListener = () => {
  const hamburger = document.querySelector('#hamburger');
  let showHeader = false;
  hamburger.addEventListener('click', () => {
    toggleHeaderView(!showHeader);
    showHeader = !showHeader;
  });
};

export const headerAnimation = () => {
  const header = document.querySelector('#header');
  window.addEventListener('scroll', () => {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      header.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
    }
  });
};
