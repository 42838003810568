// Required imports
import documentReady from './common/actions';
import { addHeaderClickListener, headerAnimation } from './common/header';
import { recordMouseClickEvents } from './common/analytics';
import videoTrigger from './video-trigger';

function callBack() {
  addHeaderClickListener();
  headerAnimation();
  window.addEventListener('click', recordMouseClickEvents);
  videoTrigger();
}

documentReady(callBack);
