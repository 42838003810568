const videoTrigger = () => {
  const videoTriggerButtons = document.querySelectorAll('.video-trigger');
  const videoPlayer = document.querySelector('#video-player');
  const videoWrapper = document.querySelector('#video-wrapper');
  const closeBtn = document.querySelector('#close-btn');

  const showVideoWrapper = () => {
    videoWrapper.classList.remove('hide');
  };

  const hideVideoWrapper = () => {
    videoPlayer.pause();
    videoWrapper.classList.add('hide');
  };

  const playVideo = (e) => {
    e.stopPropagation();
    console.log(e.target);
    if (!e.target.dataset.videoSrc) {
      console.log(e.target.parentNode.dataset.videoSrc);
      videoPlayer.src = e.target.parentNode.dataset.videoSrc;
    } else {
      console.log(e.target.dataset.videoSrc);
      videoPlayer.src = e.target.dataset.videoSrc;
    }

    showVideoWrapper();
  };

  videoTriggerButtons.forEach((element) => {
    element.addEventListener('click', (event) => {
      playVideo(event);
    });
  });

  closeBtn.addEventListener('click', hideVideoWrapper);
};

export default videoTrigger;
